import MoveTo from 'moveto';

const isElementAboveView = (el) => {
    const elemTop = el.getBoundingClientRect().top + el.offsetHeight;
    return elemTop >= 0;
};

class BackButton {
    constructor(opts) {
        this.opts = {
            text: 'Back to top',
            targetId: '#top'
        };
        this.offset = opts.offset || '0';
        this.opts = { ...this.opts, ...opts };
        this.moveTo = new MoveTo();
        this.init();
    }
    init() {
        this.addBackButton();
        this.addAnchorScroller();
        this.detectTargetInView();
    }
    addBackButton() {
        this.button = document.createElement('a');
        const {
            text,
            targetId
        } = this.opts;

        this.button.classList.add('back-button');
        this.button.setAttribute('href', '#' + targetId);
        this.button.setAttribute('data-target', targetId);
        this.button.setAttribute('data-mt-tolerance', this.offset);
        this.button.innerText = text;
        document.body.appendChild(this.button);
    }
    addAnchorScroller() {
        const anchors = document.querySelector('.back-button');
        this.moveTo.registerTrigger(anchors);
    }
    detectTargetInView() {
        let buttonVisible = true,
            target = document.getElementById(this.opts.targetId);

        const loop = () => {
            const inView = isElementAboveView(target);

            if (inView && buttonVisible) {
                this.button.classList.add('back-button--hidden');
                buttonVisible = false;
            } else if (!inView && !buttonVisible) {
                this.button.style.display = 'block';

                this.button.classList.remove('back-button--hidden');
                buttonVisible = true;
            }
        };
        window.addEventListener('scroll', (e) => {
            requestAnimationFrame(loop);
        });
        window.addEventListener('resize', (e) => {
            requestAnimationFrame(loop);
        });
        requestAnimationFrame(loop);
    }
}
export default BackButton;
