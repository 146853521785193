import BackButton from './BackButton';
const els = document.querySelectorAll('.js-back-button-target');

for (let el of els) {
    new BackButton({
        text: el.getAttribute('data-back-button-text'),
        targetId: el.getAttribute('id'),
        offset: el.getAttribute('data-back-button-offset')
    });
}
