class Tabs {
    constructor(el) {
        this.el = el;
        this.triggers = this.el.querySelectorAll('.tabs__button');
        this.tabTriggers = this.el.querySelectorAll('.tabs__button:not(.tabs__button--accordion)');
        this.content = this.el.querySelector('.tabs__content');
        this.tabFocus = 0;
        this.currenttab = this.el.querySelector('.js-current-tab') || false;
        this.tabList = this.el.querySelector('.tabs__list');
        this.toggleContent = this.toggleContent.bind(this);
        this.toggleTriggers = this.toggleTriggers.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);

        this.init();
    }

    init() {
        const { currenttab } = this;
        for (let trigger of this.triggers) {
            trigger.addEventListener('click', e => this.onClick(e));
        }
        this.tabList.addEventListener('keydown', (e) => this.handleNavigation(e));
        if (currenttab) {
            let val = currenttab.getAttribute('value');
            if (val !== '') {
                let p = document.getElementById(val);
                this.toggle(val, p);
            }
        }
    }

    getEvent(name) {
        let event;

        try {
            event = new CustomEvent(name);
        } catch (e) {
            event = document.createEvent('Event');
            event.initEvent(name, true, true);
        }

        return event;
    };

    onClick(e) {
        const { target } = e;
        const id = target.getAttribute('aria-controls');
        const panel = document.getElementById(id);

        this.toggle(id, panel);
    }

    toggle(id, panel) {
        this.toggleTriggers(id);
        this.toggleContent(id, panel);
    }

    toggleTriggers(id) {
        const activeTriggers = this.el.querySelectorAll('.tabs__button[aria-expanded="true"]');
        const nextTriggers = this.el.querySelectorAll(`.tabs__button[aria-controls="${id}"]`);
        const activeInput = this.el.querySelector('.js-current-tab');

        this.setTabindexForTriggers(-1);

        const arr = [];
        for (let trigger of this.tabTriggers) {
            arr.push(trigger.getAttribute('aria-controls'));
        }

        for (let nextTrigger of nextTriggers) {
            nextTrigger.setAttribute('aria-expanded', true);
            if (!nextTrigger.classList.contains('tabs__button--accordion')) {
                const index = arr.indexOf(nextTrigger.getAttribute('aria-controls'));

                this.tabFocus = index;
                nextTrigger.setAttribute('tabindex', 0);
            }

        }

        for (let activeTrigger of activeTriggers) {
            activeTrigger.setAttribute('aria-expanded', false);
        }

        if (activeInput) {
            if (this.el.querySelectorAll('.tabs__button[aria-expanded="true"]').length > 0) {
                activeInput.setAttribute('value', id);
            } else {
                activeInput.setAttribute('value', '');
            }
        }
    }

    toggleContent(id, panel) {
        const active = this.content.querySelector('.tabs__content-item[aria-hidden=false]');

        if (active) {
            if (active.getAttribute('id') === id) {
                active.setAttribute('aria-hidden', true);
                this.el.classList.remove('tabs--open');
                this.setTabindexForTriggers(0);
            } else {
                active.setAttribute('aria-hidden', true);
                panel.setAttribute('aria-hidden', false);
                this.el.classList.add('tabs--open');
            }
        } else {
            panel.setAttribute('aria-hidden', false);
            this.el.classList.add('tabs--open');
        }
    }

    handleNavigation(e) {
        const LEFT = 37;
        const RIGHT = 39;

        if (e.keyCode === LEFT || e.keyCode === RIGHT) {
            this.tabTriggers[this.tabFocus].setAttribute('tabindex', -1);

            // Move right
            if (e.keyCode === RIGHT) {
                this.tabFocus++;
                // If we're at the end, go to the start
                if (this.tabFocus >= this.tabTriggers.length) {
                    this.tabFocus = 0;
                }
            // Move left
            } else if (e.keyCode === LEFT) {
                this.tabFocus--;
                // If we're at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabTriggers.length - 1;
                }
            }

            this.tabTriggers[this.tabFocus].setAttribute('tabindex', 0);
            this.tabTriggers[this.tabFocus].focus();
        }
    }

    setTabindexForTriggers(tabindex) {
        for (let trigger of this.tabTriggers) {
            trigger.setAttribute('tabindex', tabindex);
        }
    }
}

export default Tabs;
