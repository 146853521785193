class LinkLinkControls {
    constructor(el) {
        this.el = el;

        this.addEventListeners();
    }

    addEventListeners() {
        const moveUpButtons = this.el.querySelectorAll('.link-list__button--prev');
        const moveDownButtons = this.el.querySelectorAll('.link-list__button--next');
        const removeButtons = this.el.querySelectorAll('.link-list__button--remove');
        const resetButton = this.el.querySelectorAll('.link-list__button--reset');

        for (let button of moveUpButtons) {
            button.addEventListener('click', (e) => this.onMoveUp(e));
        }

        for (let button of moveDownButtons) {
            button.addEventListener('click', (e) => this.onMoveDown(e));
        }

        for (let button of removeButtons) {
            button.addEventListener('click', (e) => this.onRemove(e));
        }

        for (let button of resetButton) {
            button.addEventListener('click', (e) => this.onReset(e));
        }
    }

    onMoveUp(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const currentItem = target.parentNode.parentNode;
        const prevItem = currentItem.previousElementSibling;
        const id = currentItem.getAttribute('data-id');

        if (prevItem) {
            this.el.insertBefore(currentItem, prevItem);
            this.handleIndicator(currentItem, 'up');
            target.setAttribute('disabled', 'disabled');

            setTimeout(() => {
                target.removeAttribute('disabled');
                this.el.querySelector(`.link-list__item[data-id="${id}"] .link-list__button--prev`).focus();
            }, 300);
        }
    }

    onMoveDown(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const currentItem = target.parentNode.parentNode;
        const nextItem = currentItem.nextElementSibling;

        if (nextItem) {
            this.el.insertBefore(nextItem, currentItem);
            this.handleIndicator(currentItem, 'down');
            target.setAttribute('disabled', 'disabled');

            setTimeout(() => {
                target.removeAttribute('disabled');
                target.focus();
            }, 300);
        }
    }

    onRemove(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const currentItem = target.parentNode.parentNode;
        const buttons = currentItem.querySelectorAll('.link-list__button');

        currentItem.classList.add('link-list__item--remove');

        for (let button of buttons) {
            if (!button.classList.contains('link-list__button--reset')) {
                button.setAttribute('disabled', 'disabled');
            }
        }
    }

    onReset(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const currentItem = target.parentNode.parentNode;
        const buttons = currentItem.querySelectorAll('.link-list__button');

        currentItem.classList.remove('link-list__item--remove');

        for (let button of buttons) {
            button.removeAttribute('disabled');
        }
    }

    handleIndicator(item, direction) {
        item.classList.add(`link-list__item--animate-${direction}`);

        setTimeout(() => {
            item.classList.remove(`link-list__item--animate-${direction}`);
        }, 300);
    }
};

export default LinkLinkControls;
