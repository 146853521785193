import throttle from 'lodash/throttle';

class TableRte {
    constructor(el) {
        this.el = el;
        this.scroller = this.el.firstElementChild;
        this.table = this.el.querySelector('.table-rte__scroller table');
        this.toggleIcon = this.toggleIcon.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.checkOverflow = this.checkOverflow.bind(this);
        this.init();
    }

    init() {
        this.checkOverflow();
        window.addEventListener('resize', throttle(this.checkOverflow, 100));
        this.scroller.addEventListener('scroll', throttle(this.handleScroll, 100));
    }

    checkOverflow() {
        if (this.table) {
            this.parentWidth = this.el.offsetWidth;
            this.isOverflow = this.parentWidth < this.table.offsetWidth;
            this.toggleIcon(this.isOverflow);
            this.checkScrollPosition();
        }
    }

    handleScroll() {
        this.toggleIcon(false);
        this.checkScrollPosition();
    }

    checkScrollPosition() {
        const scrollPosition = this.table.parentNode.scrollLeft + this.scroller.getBoundingClientRect().width;
        const tableWidth = this.table.offsetWidth;

        this.el.classList.toggle('table-rte--overflow-right', scrollPosition !== tableWidth);
        this.el.classList.toggle('table-rte--overflow-left', scrollPosition > this.scroller.getBoundingClientRect().width);
    }

    toggleIcon(visible) {
        this.el.classList.toggle('table-rte--icon', visible);
    }
}

export default TableRte;
