import TabLoop from '../../functions/TabLoop';

class Modal {
    constructor(modal) {
        this.modal = modal;
        this.state = 'hidden';
        this.id = modal.getAttribute('id');
        this.lastFocusedElement = undefined;
        this.tabLoop = new TabLoop(this.modal);
        this.ESCKEY = 27;
        this.keyUpHandler = e => this.keyUp(e);
        this.clickOutsideHandler = e => this.clickOutside(e);
        this.setModalStateHandler = e => this.setModalState(e);
        this.modal.modal = this;
        this.publicOpen = (tabLoop) => this.open(tabLoop);
        this.publicClose = () => this.close();

        this.init();
    }

    init() {
        this.attachTriggers();

        // Reattach trigger listeners after DOM update
        window.addEventListener('liveFilterUpdate', () => this.attachTriggers());
        window.addEventListener('loadMoreUpdate', () => this.attachTriggers());
    }

    attachTriggers() {
        const triggers = document.querySelectorAll(`[aria-controls="${this.id}"]`);

        for (let i = 0; i < triggers.length; i++) {
            const trigger = triggers[i];
            trigger.addEventListener('click', this.setModalStateHandler);
        }
    }

    setModalState(e) {
        e.preventDefault();
        if (this.state === 'hidden') {
            this.lastFocusedElement = e.currentTarget;
        }
        const targetmodal = this.modal.modal.modal;
        const endpoint = e.currentTarget.getAttribute('data-endpoint') || null;
        // if link has endpoint fetch endpoint and then activate modal
        if (endpoint) {
            // clear existing content in modal
            targetmodal.querySelector('.modal__title').innerHTML = '';
            targetmodal.querySelector('.modal__content-inner').innerHTML = '';
            targetmodal.querySelector('.modal__call-to-action').innerHTML = '';
            // fetch endpoint
            fetch(endpoint, { method: 'GET', headers: { 'content-type': 'application/json', 'X-Requested-With' : 'XMLHttpRequest' } })
                .then(r => r.json())
                .then(data => {
                    if (data.success) {
                        targetmodal.querySelector('.modal__title').innerHTML = data.title;
                        targetmodal.querySelector('.modal__content-inner').innerHTML = data.content;
                        targetmodal.querySelector('.modal__call-to-action').innerHTML = data.callToAction;
                        this.toggle();
                    }
                    if (!data.success) {
                        console.error(data);
                    }
                });
        } else {
            this.toggle();
        }
    }

    addEventListeners() {
        document.addEventListener('keyup', this.keyUpHandler);
        document.addEventListener('click', this.clickOutsideHandler);
    }
    removeEventListeners() {
        document.removeEventListener('keyup', this.keyUpHandler);
        document.removeEventListener('click', this.clickOutsideHandler);
    }
    keyUp(e) {
        if (e.which === this.ESCKEY) {
            e.preventDefault();
            this.close();
        }
    }

    clickOutside(e) {
        const target = e.target;

        if (target === this.modal.children[0]) {
            this.close();
        }
    }

    open() {

        this.modal.style.display = 'block';
        this.modal.style.overflowX = 'hidden';

        setTimeout(() => {
            this.modal.setAttribute('aria-hidden', false);
            this.state = 'visible';
            const event = this.getEvent('modalInView');

            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
            this.tabLoop.start();
            this.addEventListeners();

            this.modal.dispatchEvent(event);
        }, 30);
    }

    close() {
        const event = this.getEvent('modalClose');
        this.modal.setAttribute('aria-hidden', true);
        this.state = 'hidden';

        document.body.style.height = 'auto';

        this.lastFocusedElement.focus();
        this.tabLoop.stop();
        this.removeEventListeners();

        setTimeout(() => {
            this.modal.style.display = 'none';
            this.modal.style.overflowX = '';
            document.body.style.overflow = '';

            this.modal.dispatchEvent(event);
        }, 250);
    }

    toggle() {
        if (this.state === 'hidden') {
            this.open();
        } else {
            this.close();
        }
    }

    getEvent(name) {
        let event;

        try {
            event = new CustomEvent(name);
        } catch (e) {
            event = document.createEvent('Event');
            event.initEvent(name, true, true);
        }

        return event;
    };
}

export default Modal;
