class DetailsToggler {
    constructor(details) {
        this.details = details;
        this.detailsSummary = this.details.getElementsByTagName('summary')[0];
        this.SPACEKEY = 32;
        this.ENTERKEY = 13;

        if (this.detailsSummary) {
            this.addEventListeners();
        }
    }

    addEventListeners() {
        // If details el is not natively supported
        if (!('open' in document.createElement('details'))) {
            setTimeout(() => {
                this.triggerGridUpdate();
            }, 0);

            this.detailsSummary.addEventListener('keydown', (e) => {
                if (e.which === this.SPACEKEY || e.which === this.ENTERKEY) {
                    e.preventDefault();
                    this.toggle();
                }
            });
        }

        this.detailsSummary.addEventListener('click', () => {
            this.triggerGridUpdate();
        });
    }

    getEvent(name) {
        let event;

        try {
            event = new CustomEvent(name);
        } catch (e) {
            event = document.createEvent('Event');
            event.initEvent(name, true, true);
        }

        return event;
    };

    triggerGridUpdate() {
        const event = this.getEvent('gridUpdate');

        window.dispatchEvent(event);
    }

    toggle() {
        if (this.details.getAttribute('open') === 'true') {
            this.details.removeAttribute('open');
        } else {
            this.details.setAttribute('open', 'true');
        }

    }
}

export default DetailsToggler;
