import lineClamp from 'line-clamp';

const addLineClamp = () => {
    const els = document.querySelectorAll('.js-line-clamp');

    for (let el of els) {
        lineClamp(el, 3);
    }
};

addLineClamp();

window.addEventListener('loadMoreUpdate', addLineClamp);
