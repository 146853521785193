const masonryGrids = document.querySelectorAll('.js-grid-masonry');
const MagicGrid = window.MagicGrid || null;

if (MagicGrid !== null && masonryGrids.length > 0) {
    for (let i = 0; i < masonryGrids.length; i++) {
        masonryGrids[i].classList.add('grid-container--masonry');
    }

    let magicGrid = new MagicGrid({
        container: '.grid-container--masonry', // Required. Can be a class, id, or an HTMLElement.
        static: true, // Required for static content.
        maxColumns: 2,
        useMin: true,
        gutter: 24,
        animate: false
    });

    magicGrid.listen();

    setTimeout(() => {
        magicGrid.positionItems();
    }, 500);

    window.addEventListener('gridUpdate', () => {
        setTimeout(() => {
            magicGrid.positionItems();
        }, 0);
    });
}
