import Modal from './Modal';

const initModals = () => {
    const els = document.querySelectorAll('.modal');

    for (let el of els) {
        new Modal(el);
    }
};

initModals();

window.addEventListener('loadMoreUpdate', initModals);
window.addEventListener('liveFilterUpdate', initModals);
