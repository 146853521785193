class TabLoop {
    constructor(el) {
        this.el = el;
        this.focusableElements = 'a, button, input, textarea, select';
        this.firstFocusableEl = undefined;
        this.lastFocusableEl = undefined;
        this.TABKEY = 9;

        this.keyDownHandler = e => this.keydown(e);

        this.setFirstAndLastFocusableElements();
    }

    setFirstAndLastFocusableElements() {
        const elements = this.el.querySelectorAll(this.focusableElements);
        let arr = [];

        for (let i = 0; i < elements.length; i++) {
            arr.push(elements[i]);
        }

        this.firstFocusableEl = arr[0];
        this.lastFocusableEl = arr[arr.length - 1];

    }

    start() {
        this.firstFocusableEl.focus();

        this.firstFocusableEl.addEventListener('keydown', this.keyDownHandler);
        this.lastFocusableEl.addEventListener('keydown', this.keyDownHandler);
    }

    stop() {
        this.firstFocusableEl.removeEventListener('keyup', this.keyDownHandler);
        this.lastFocusableEl.removeEventListener('keyup', this.keyDownHandler);
    }

    keydown(e) {
        const target = e.target;

        if (target === this.firstFocusableEl && e.which === this.TABKEY && e.shiftKey) {
            e.preventDefault();
            this.lastFocusableEl.focus();
        }

        if (target === this.lastFocusableEl && e.which === this.TABKEY && !e.shiftKey) {
            e.preventDefault();
            this.firstFocusableEl.focus();
        }
    }
}

export default TabLoop;
